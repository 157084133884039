import ReactPlayer from "react-player/youtube"

function App() {
  return (
    <div class="container">
      <ReactPlayer
        url="https://youtu.be/O96W1xkI6jo"
        width="100%"
        height="300px"
      />
      <h1>Jaz Gulati</h1>
      <p>Updated <time datetime="2024-07-10">July 10, 2024</time></p>
      <h2>About me:</h2>
      <ul>
        <li>Enjoyer of sounds</li>
        <li>Founder of <a href="https://grassroots.studio">Grassroots Studio</a>, a software company
          <ul>
            <li>Now: Making Web3 friendly at <a href="https://catalog.fi">Catalog</a> and <a href="https://garden.finance">Garden</a></li>
            <li>Past: Worked on <a href="https://renprotocol.org">Ren</a>, a protocol that enables the movement of value between blockchains</li>
          </ul>
        </li>
      </ul>
      <h2>Current rotation:</h2>
      <ul>
        <li>Bone Thugs-n-Harmony - Creepin on Ah Come Up <span>(Gangsta Rap, 1994)</span></li>
        <li>42 Dugg - 4eva Us Neva Them <span>(Detroit Trap, 2024)</span></li>
        <li>NxWorries - Why Lawd? <span>(Funk Rap, 2024)</span></li>
        <li>Raveena - Where the Butterflies Go in the Rain <span>(Contemporary R&B, 2024)</span></li>
        <li>Amaarae - roses are red, tears are blue <span>(Alté, 2024)</span></li>
        <li>French Montana & Max B - Coke Wave <span>(Gangsta Rap, 2009)</span></li>
        <li>KAYTRANADA - Timeless <span>(Alternative R&B, 2024)</span></li>
        <li>Gucci Mane & Waka Flocka Flame - Ferrari Boyz <span>(Trap, 2011)</span></li>
        <li>Tems - Born in the Wild <span>(Alté, 2024)</span></li>
        <li>Flo Milli - Fine Ho, Stay <span>(Pop Rap, 2024)</span></li>
      </ul>
    </div>
  );
}

export default App;
